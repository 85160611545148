import React from "react";
import { StatusIcon } from "../../../components/SvgIcons/Icons";
import { FiEdit2 } from "react-icons/fi";

function PetitionStageTag({
  stage = "ui",
  showIndicator = true,
  onClick,
  ...props
}) {
  let value = stage.toUpperCase();
  return (
    <div className="d-inline-flex align-items-center petition-stage-tag">
      {showIndicator && (
        <StatusIcon
          fill={
            value === "UI"
              ? "#17B26A"
              : value === "CLOSED"
              ? "#F04438"
              : "#2e90fa"
          }
        />
      )}
      <span className="ms-1" onClick={onClick} {...props}>
        {value ? value.toUpperCase() : "UI"}
      </span>
    </div>
  );
}

export default PetitionStageTag;
