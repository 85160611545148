import React from "react";
import PropTypes from "prop-types";
import "./index.css";
import classNames from "classnames";

function TextInput({
  type = "text",
  maxLength,
  onChange = () => {},
  fieldName = "",
  placeholder = "Please enter",
  required = false,
  label = "",
  inputGroup = false,
  icon = null,
  inputGroupLabel = "",
  className = "",
  value = "",
  containerClass = "col-6",
  error = "",
  onClickIcon = () => {},
}) {
  const handleOnChange = (e) => {
    onChange(e.target.value, fieldName);
  };

  return (
    <div className={`app-input-container ${containerClass}`}>
      {label ? (
        <label
          htmlFor={fieldName}
          className={classNames("input-label", {
            "input-required": required,
          })}
        >
          {label}
        </label>
      ) : null}
      <div>
        {inputGroup ? <div>{inputGroupLabel}</div> : null}
        <input
          type={type}
          id={fieldName}
          onChange={(e) => handleOnChange(e)}
          placeholder={placeholder}
          maxLength={maxLength}
          className={`app-input ${className} ${error ? "error-input" : ""}`}
          value={value}
        />
      </div>
    </div>
  );
}

TextInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default TextInput;
