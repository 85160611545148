import React from "react";
import "./index.css";

function Header({ children }) {
  return (
    <div className="header d-flex align-items-center justify-content-between">
      <div>
        <p className="app-name">E - Petition Management</p>
        <p className="app-tagline">Track, manage and review petition.</p>
      </div>
      <div>{children}</div>
    </div>
  );
}

export default Header;
