import React from "react";
import { Modal } from "antd";
import "./index.css";

const PetitionDetailsModal = ({ visible, onCancel, petitionData }) => {
  return (
    <Modal
      className="petition-modal d-flex align-items-center justify-content-center"
      title={`${petitionData?.petitionSource?.name} - ${petitionData?.petitionId}`}
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div className="petition-section d-flex row justify-content-center">
        <h4>Complainant Details</h4>
        <p>
          Name: {petitionData?.petitionerFirstname}{" "}
          {petitionData?.petitionerLastname}
        </p>
        <p>Gender: {petitionData?.petitionerGender || "-"}</p>
        <p>Age: {petitionData?.petitionerAge || "-"}</p>
        <p>Phone no: {petitionData?.petitionerMobile || "-"}</p>
        <p>Community: {petitionData?.petitionerCommunity || "-"}</p>
        <p>Address: {petitionData?.petitionerAddress || "-"}</p>
      </div>
      <div className="petition-section">
        <h4>Suspect Details</h4>
        <p>
          Name: {petitionData?.suspectFirstname} {petitionData?.suspectLastname}
        </p>
        <p>Gender: {petitionData?.suspectGender || "-"}</p>
        <p>Age: {petitionData?.suspectAge || "-"}</p>
        <p>Phone no: {petitionData?.suspectMobile || "-"}</p>
        <p>Community: {petitionData?.suspectCommunity || "-"}</p>
        <p>Address: {petitionData?.suspectAddress || "-"}</p>
      </div>
      <div className="petition-section">
        <h4>Petition Details</h4>
        <p>Brief of the Details: {petitionData?.briefOfIncident || "-"}</p>
        <p>Days to solve: {petitionData?.daysRequired || "-"}</p>
      </div>
    </Modal>
  );
};

export default PetitionDetailsModal;
