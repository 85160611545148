export const daysRequiredMaster = [
  {
    value: 3,
    name: "3 days",
  },
  {
    value: 7,
    name: "7 days",
  },
  {
    value: 14,
    name: "14 days",
  },
  {
    value: 21,
    name: "21 days",
  },
  {
    value: 30,
    name: "30 days",
  },
  {
    value: 45,
    name: "45 days",
  },
  {
    value: 60,
    name: "60 days",
  },
];

export const genderMaster = [
  {
    name: "Male",
    value: "Male",
  },
  {
    name: "Female",
    value: "Female",
  },
  {
    name: "Other",
    value: "Other",
  },
];
