export const stageConstants = [
  {
    name: "UI",
    value: "ui",
  },
  {
    name: "CLOSED",
    value: "closed",
  },
  {
    name: "FIR FILED",
    value: "firfiled",
  },
];
