export const validateForm = (model = [], formValue = {}) => {
  let newForm = { ...formValue };
  let isFormValid = true;
  model.forEach((field) => {
    let isfiledValid = true;
    if (field.required) {
      if (!formValue[field.fieldName]) {
        isFormValid = false;
        isfiledValid = false;
        newForm[`${field.fieldName}Error`] = "This field is required";
        return;
      }
    }
    if (field?.validators) {
      field.validators.forEach((validator) => {
        const result = validator(formValue[field.fieldName]);
        if (!result.valid) {
          isFormValid = false;
          isfiledValid = false;
          newForm[`${field.fieldName}Error`] = result.message;
        }
      });
    }
    if (isfiledValid) {
      newForm[`${field.fieldName}Error`] = "";
    }
  });
  return { newForm: { ...newForm }, isValid: isFormValid };
};
