import React from "react";
import "./index.css";
import { TailSpin } from "react-loader-spinner";

function Button({
  title = "",
  loading = false,
  disabled = false,
  icon = null,
  className = "",
  onClick = () => {},
}) {
  return (
    <button
      className={`app-btn primary ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {!loading ? (
        <span>{title}</span>
      ) : (
        <div className="d-flex justify-content-center">
          <TailSpin radius={1} color="#fff" width={25} height={25} />
        </div>
      )}
    </button>
  );
}

export default Button;
