import loaderSlice from "./commonActions/loaderSlice";
import masterSlice from "./masters/masterSlice";
import petitionsSlice from "./petitions/petitionsSlice";
import userSlice from "./slices/userSlice";

export const rootReducer = {
  loader: loaderSlice,
  petitions: petitionsSlice,
  masterData: masterSlice,
  user: userSlice,
};
