function toCamelCase(str) {
    return str.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace("-", "").replace("_", "");
    });
  }
  
  export function convertToCamelCase(value) {
    if(!value) return value
    if (
      value === Object(value) &&
      !Array.isArray(value) &&
      typeof value !== "function"
    ) {
      const result = {};
      Object.keys(value).forEach((eachValue) => {
        result[toCamelCase(eachValue)] = convertToCamelCase(value[eachValue]);
      });
      return result;
    } else if (Array.isArray(value)) {
      return value.map((i) => {
        return convertToCamelCase(i);
      });
    }
  
    return value;
  }
  