import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import Layout from "../main/Layout";

const Protected = ({ children }) => {
  const value = localStorage.getItem("access_token");

  let Component = Layout;

  if (value === null || value === undefined) {
    return <Navigate to="/" replace />;
  }
  return <Component>{children}</Component>;
};

Protected.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Protected;
