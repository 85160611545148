import React, { useState } from "react";
import { Modal, Button } from "antd";

const TopRightModal = ({ children, open, title, onClose }) => {
  const [visible, setVisible] = useState(true);

  return (
    <div>
      <Modal
        open={open}
        footer={() => null}
        onClose={onClose}
        width={400}
        onCancel={onClose}
        maskClosable={false}
      >
        {children}
      </Modal>
    </div>
  );
};

export default TopRightModal;
