// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-btn {
  padding: 10px 14px;
  border-radius: var(--radius-md, 8px);
  /* border: 2px rgba(255, 255, 255, 0.12); */
  outline: none;
  border: none;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.app-btn.primary{
    background-color: #2E90FA;
}

.app-btn.danger{
    background-color: #F04438;
}

.app-btn.success{
    background-color: #079455;
}

`, "",{"version":3,"sources":["webpack://./src/components/commonComponents/Button/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oCAAoC;EACpC,2CAA2C;EAC3C,aAAa;EACb,YAAY;EACZ,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".app-btn {\n  padding: 10px 14px;\n  border-radius: var(--radius-md, 8px);\n  /* border: 2px rgba(255, 255, 255, 0.12); */\n  outline: none;\n  border: none;\n  color: #fff;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 20px;\n}\n\n.app-btn.primary{\n    background-color: #2E90FA;\n}\n\n.app-btn.danger{\n    background-color: #F04438;\n}\n\n.app-btn.success{\n    background-color: #079455;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
