import { apiUrls } from "../../../common/apiUrls";
import { getUrl, patchUrl, postUrl } from "../../../common/epetitionApi";
import { convertToCamelCase } from "../../../utils/camelCaseConverter";

export const createOrEditPetition = async (
  petitionData,
  petitionId = "",
  assets
) => {
  const petitionFormData = new FormData();
  const {
    complaintDetailsForm,
    suspectDetailsForm,
    petitionDetailsBriefForm,
    petitionDetailsForm,
  } = petitionData;
  petitionFormData.append(
    "petitioner_firstname",
    complaintDetailsForm?.firstName || ""
  );
  petitionFormData.append(
    "petitioner_lastname",
    complaintDetailsForm?.lastName || ""
  );
  petitionFormData.append("petitioner_age", complaintDetailsForm?.age || "");
  petitionFormData.append(
    "petitioner_gender",
    complaintDetailsForm?.gender || ""
  );
  petitionFormData.append(
    "petitioner_mobile",
    complaintDetailsForm?.phoneNumber || ""
  );
  petitionFormData.append(
    "petitioner_community",
    complaintDetailsForm?.community || ""
  );
  petitionFormData.append(
    "petitioner_address",
    complaintDetailsForm?.address || ""
  );

  petitionFormData.append(
    "suspect_firstname",
    suspectDetailsForm?.firstName || ""
  );
  petitionFormData.append(
    "suspect_lastname",
    suspectDetailsForm?.lastName || ""
  );
  petitionFormData.append("suspect_age", suspectDetailsForm?.age || "");
  petitionFormData.append("suspect_gender", suspectDetailsForm?.gender || "");
  petitionFormData.append(
    "suspect_mobile",
    suspectDetailsForm?.phoneNumber || ""
  );
  petitionFormData.append(
    "suspect_community",
    suspectDetailsForm?.community || ""
  );
  petitionFormData.append("suspect_address", suspectDetailsForm?.address || "");
  petitionFormData.append("division", petitionDetailsForm?.division || "");
  petitionFormData.append("ps", petitionDetailsForm?.psLimit || "");
  petitionFormData.append(
    "petition_source",
    petitionDetailsForm?.petitionSource || ""
  );

  petitionFormData.append(
    "brief_of_incident",
    petitionDetailsBriefForm?.briefOfIncident || ""
  );
  petitionFormData.append(
    "days_required",
    petitionDetailsForm?.daysRequired || ""
  );
  for (let i = 0; i < assets.length; i++) {
    petitionFormData.append("documents", assets[i], assets[i].name);
  }

  if (petitionId != null && petitionId !== undefined && petitionId !== "") {
    //Should update petition in this case;
    const response = await patchUrl(
      apiUrls.petition(petitionId),
      petitionFormData,
      true,
      true
    );
    if (response?.status === 200) {
      return { success: true };
    } else {
      return { success: false };
    }
  } else {
    const response = await postUrl(
      apiUrls.newPetition,
      petitionFormData,
      true,
      true
    );
    if (response?.status === 201) {
      return { success: true };
    } else {
      return { success: false };
    }
  }
};

const mapFieldsToPetitionValues = (values) => {
  if (!values) {
    return {};
  }
  let peitionForm = convertToCamelCase(values);
  const formData = {
    complaintDetailsForm: {
      firstName: peitionForm.petitionerFirstname || "",
      lastName: peitionForm.petitionerLastname || "",
      age: peitionForm.petitionerAge || "",
      gender: peitionForm.petitionerGender || "",
      community: peitionForm.petitionerCommunity || "",
      address: peitionForm.petitionerAddress || "",
      phoneNumber: peitionForm.petitionerMobile || "",
    },
    suspectDetailsForm: {
      firstName: peitionForm.suspectFirstname || "",
      lastName: peitionForm.suspectLastname || "",
      age: peitionForm.suspectAge || "",
      gender: peitionForm.suspectGender || "",
      community: peitionForm.suspectCommunity || "",
      address: peitionForm.suspectAddress || "",
      phoneNumber: peitionForm.suspectMobile || "",
    },
    petitionDetailsBriefForm: {
      briefOfIncident: peitionForm.briefOfIncident,
    },
    petitionDetailsForm: {
      daysRequired: peitionForm?.daysRequired,
      division: peitionForm?.division?.id,
      psLimit: peitionForm?.ps?.id,
      petitionSource: peitionForm?.petitionSource?.id,
    },
    metadata: {
      petitionId: peitionForm.petitionId,
    },
  };
  return formData;
};

export const getPetition = async (petitionId) => {
  try {
    const response = await getUrl(apiUrls.petition(petitionId));
    if (response.status === 200 && response.data) {
      let result = mapFieldsToPetitionValues(response.data);
      return { form: result, success: true };
    } else {
      return { ...response };
    }
  } catch (err) {
    return { sucess: false };
  }
};
