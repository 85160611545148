import React, { useState } from "react";
import "./index.css";
import { Upload } from "antd";
import { UploadIcons } from "../../SvgIcons/Icons";
import { AiOutlineClose } from "react-icons/ai";
import { Tooltip } from "antd";

function FileInput({
  multiple = true,
  label = "Pdf/Image",
  required = false,
  fieldName,
  containerClass = "col-6",
  onChange = () => {},
}) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [error, setError] = useState("");

  const handleOnChange = (e) => {
    let inputFiles = e.target.files;
    if (e.target.files.length > 5) {
      return setError("You cannot select more than 5 files.");
    }
    if (selectedFiles.length > 5) {
      return setError("You cannot select more than 5 files.");
    }
    if (selectedFiles.length + inputFiles.length > 5) {
      setError("You cannot select more than 5 files.");
    }
    setSelectedFiles((prev) => {
      onChange([...prev, ...inputFiles].splice(0, 5));
      return [...prev, ...inputFiles].splice(0, 5);
    });
  };

  const handleClear = (fileIndex) => {
    let filteredFiles = selectedFiles.filter((_, index) => index !== fileIndex);
    setSelectedFiles([...filteredFiles]);
    onChange([...filteredFiles]);
  };

  return (
    <div>
      <p className="input-label">Upload petition assets if any</p>
      <label htmlFor={fieldName} className={`${containerClass}`}>
        <div className={`file-upload-container`}>
          <div className="d-flex align-items-center mt-3 mb-3">
            <UploadIcons />
            <p className="ms-2">Upload</p>
          </div>
          <p className="mb-2" style={{ color: "#344054" }}>
            Note that you can only upload maximum 5 files*
          </p>
        </div>
      </label>
      <input
        type="file"
        multiple={multiple}
        className="d-none"
        id={fieldName}
        onChange={handleOnChange}
      />
      {error ? <p className="error_msg">{error}</p> : null}
      {selectedFiles.map((filesInfo, index) => {
        return (
          <div className="mt-2 text-standard d-flex justify-content-between border border-white align-items-center p-2 ">
            <div>
              <img
                src="/attach-file.png"
                style={{ width: 15, height: 15 }}
                alt="attachment"
              />
              <span className="ms-2">{filesInfo.name}</span>
            </div>
            <Tooltip title="Delete attachment">
              <AiOutlineClose
                color="red"
                className="ms-4"
                style={{ cursor: "pointer" }}
                onClick={() => handleClear(index)}
              />
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
}

export default FileInput;
