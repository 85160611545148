import React, { useEffect, useState } from "react";
import TopRightModal from "../../../../components/commonComponents/Modal/ModalNotification";
import { TiArrowForwardOutline } from "react-icons/ti";
import Dropdown from "../../../../components/commonComponents/Dropdown";
import Button from "../../../../components/commonComponents/Button";
import { forwardPetition } from "../PetitionListing.actions";
import { notification } from "antd";

function ForwardPetitionModal({
  open = false,
  onClose = () => {},
  petition = {},
}) {
  const [forwardOptions, setForwardOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    petition?.assigned_to || null
  );
  const [loading, setLoading] = useState(false);
  const [api, notificationContext] = notification.useNotification();

  useEffect(() => {
    setForwardOptions(() => [
      {
        value: petition?.division?.id || null,
        name: petition?.division?.name || "Invalid division",
      },
      {
        value: petition?.ps?.id || null,
        name: petition?.ps?.name || "Invalid PS",
      },
    ]);
    setSelectedValue(petition?.assignedTo || null);
  }, [petition]);

  const handleOnChange = (value) => {
    setSelectedValue(value);
  };

  const handleOnClose = () => {
    setSelectedValue(null);
    setForwardOptions([]);
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    const result = await forwardPetition(petition.id, selectedValue);
    if (result.success && result.status === 200) {
      api.success({
        message: "Success!",
        description: `Petition ${petition.petitionId} has been forwarded succesfully!`,
      });
    } else {
      api.error({
        message: "Unable to forward!",
        description: `An issue occured while forward the issue. Try again later!`,
      });
    }
    setLoading(false);
    handleOnClose();
  };

  return (
    <div>
      {notificationContext}
      <TopRightModal onClose={handleOnClose} open={open}>
        <div className="d-flex flex-column justify-content-center mt-3">
          <div className="d-flex align-items-center">
            <div className="success-container d-flex align-items-center">
              <TiArrowForwardOutline size={30} color="green" />
            </div>
            <strong className="ms-3">
              Forwarding {petition?.petitionId || "-"}
            </strong>
          </div>
          <div className="mt-4">
            <Dropdown
              fieldName="forwardTo"
              onChange={handleOnChange}
              containerClass="col-12 w-100"
              label="Forward Petition To"
              options={forwardOptions}
              value={selectedValue}
            />
          </div>
        </div>
        <Button
          loading={loading}
          title="Forward"
          className="w-100 mt-3"
          onClick={handleSubmit}
        />
      </TopRightModal>
    </div>
  );
}

export default ForwardPetitionModal;
