import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { daysRequiredMaster, genderMaster } from "./masterConstants";
import { getUrl } from "../../common/epetitionApi";
import { apiUrls } from "../../common/apiUrls";

let initialState = {
  divisions: [],
  policeStations: [],
  petitionSource: [],
  gender: [...genderMaster],
  daysRequired: [...daysRequiredMaster],
};

const converToMasterData = (values) => {
  if (Array.isArray(values) && values.length > 0) {
    return values.map((data) => ({ ...data, value: data.id }));
  } else {
    return [];
  }
};

export const getDivisions = createAsyncThunk(
  "masterData/divisions",
  async (payload, _) => {
    const result = await getUrl(apiUrls.divisions);
    if (result.success) {
      return result.data;
    } else {
      throw new Error("Unable to fetch divisions for the user");
    }
  }
);

export const getPoliceLimits = createAsyncThunk(
  "masterData/policLimits",
  async (payload, _) => {
    const result = await getUrl(apiUrls.ps(payload));
    if (result.success) {
      return result.data;
    } else {
      throw new Error("Unable to fetch ps for given division");
    }
  }
);

export const getPetitionSource = createAsyncThunk(
  "masterData/petitionSource",
  async (payload, _) => {
    const result = await getUrl(apiUrls.petitionSource);
    if (result.success) {
      return result.data;
    } else {
      throw new Error("Unable to fetch petition source for the user");
    }
  }
);

const masterSlice = createSlice({
  name: "masterData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDivisions.fulfilled, (state, action) => {
        state.divisions = converToMasterData(action.payload);
      })
      .addCase(getDivisions.rejected, (state, action) => {
        state.divisions = [];
      })
      .addCase(getPoliceLimits.fulfilled, (state, action) => {
        state.policeStations = converToMasterData(action.payload);
      })
      .addCase(getPoliceLimits.rejected, (state, _) => {
        state.policeStations = [];
      })
      .addCase(getPetitionSource.fulfilled, (state, action) => {
        state.petitionSource = converToMasterData(action.payload);
      })
      .addCase(getPetitionSource.rejected, (state, action) => {
        state.petitionSource = [];
      });
  },
});

export default masterSlice.reducer;
