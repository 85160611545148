import React, { useEffect, useState } from "react";
import TopRightModal from "../../../../components/commonComponents/Modal/ModalNotification";
import Dropdown from "../../../../components/commonComponents/Dropdown";
import Button from "../../../../components/commonComponents/Button";
import { notification } from "antd";
import { stageConstants } from "./Stage.constants";
import { MdIncompleteCircle } from "react-icons/md";
import { updatePetitionStage } from "../PetitionListing.actions";
import { useDispatch } from "react-redux";
import { fetchPetitions } from "../../../../store/petitions/petitionsSlice";
function StageModal({ open = false, onClose = () => {}, petition = {} }) {
  const [selectedValue, setSelectedValue] = useState(petition?.stage || null);
  const [loading, setLoading] = useState(false);
  const [api, notificationContext] = notification.useNotification();
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedValue(petition.state || "");
  }, [petition]);

  const handleOnChange = (value) => {
    setSelectedValue(value);
  };

  const handleOnClose = () => {
    setSelectedValue(null);
    onClose();
  };

  const handleSubmit = async () => {
    setLoading(true);
    let result = await updatePetitionStage(selectedValue, petition.id);
    if (result.success && result.status === 200) {
      api.success({
        message: "Success!",
        description: `Stage of petition ${petition.petitionId} has been updated succesfully!`,
      });
      dispatch(fetchPetitions());
    } else {
      api.error({
        message: "Unable to set stage!",
        description: `An issue occured while updating stage the issue. Try again later!`,
      });
    }
    setLoading(false);
    handleOnClose();
  };

  return (
    <div>
      {notificationContext}
      <TopRightModal onClose={handleOnClose} open={open}>
        <div className="d-flex flex-column justify-content-center mt-3">
          <div className="d-flex align-items-center">
            <div className="success-container d-flex align-items-center">
              <MdIncompleteCircle size={30} color="green" />
            </div>

            <strong className="ms-3">
              Updating stage - {petition?.petitionId || "-"}
            </strong>
          </div>
          <div className="mt-4">
            <Dropdown
              id="stage-dropdown"
              options={stageConstants}
              value={selectedValue}
              onChange={handleOnChange}
              containerClass="col-12 w-100"
            />
          </div>
        </div>
        <Button
          loading={loading}
          title="Update Stage"
          className="w-100 mt-3"
          onClick={handleSubmit}
        />
      </TopRightModal>
    </div>
  );
}

export default StageModal;
