import React from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  HomeIcon,
  LogoutIcon,
  NominalRoleIcon,
  SessionsIcon,
  StatementsIcon,
  WorkAuditLog,
} from "../components/SvgIcons/Icons";
import "./index.css";
import { Divider } from "antd";
import { fetchUser } from "../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";

function SideNaveBar() {
  const dispatch = useDispatch();
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch]);
  const user = useSelector((state) => state.user);
  return (
    <div className="app-sidebar">
      <div style={{ width: "100%" }}>
        {/* logo shoulg go here */}
        {/* <img className="logo" alt="logo" src="/logo.svg" /> */}
        <p className="logo">E-Petition</p>
        <ul className="sidebar-upper-nav">
          <li>
            <NavLink to={"/petitions"} className="link-item">
              <HomeIcon />
              <span>Petitions</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/statements"} className="link-item">
              <StatementsIcon />
              <span>Reports</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/sessions"} className="link-item">
              <WorkAuditLog />
              <span>Work Audits</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/sessions"} className="link-item">
              <NominalRoleIcon />
              <span>Nominal Role</span>
            </NavLink>
          </li>
          {/* <li>
            <NavLink to={"/sessions"} className="link-item">
              <SessionsIcon />
              <span>Sessions</span>
            </NavLink>
          </li> */}
          {/* <li>
            <NavLink to={"/sessions"} className="link-item">
              <SessionsIcon />
              <span>Create users</span>
            </NavLink>
          </li> */}
        </ul>
      </div>
      <div className="sidebar-lower-nav">
        <div className="copy-rights">
          <p className="c">Copyright © {new Date().getFullYear()}</p>
          <p className="company">Asterteq Ventures Pvt Ltd</p>
        </div>
        <Divider />
        <div className="d-flex justify-content-between align-items-center logout mb-4">
          <div className="d-flex align-items-center">
            <img alt="avatar" src="/police-officer.png" className="avatar" />
            <div className="ms-2">
              <p className="username">{user?.user?.username}</p>
            </div>
          </div>
          <NavLink to={"/logout"}>
            <LogoutIcon />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default SideNaveBar;
