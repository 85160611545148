import { apiUrls } from "../../../common/apiUrls";
import { postUrl, patchUrl } from "../../../common/epetitionApi";

export const forwardPetition = async (petitionId, orgId) => {
  const repsose = await postUrl(apiUrls.forwardPetiton(petitionId), {
    org_id: orgId,
  });
  return repsose;
};

export const replyComment = async (replyComment, petitionId) => {
  const ReplyData = new FormData();
  ReplyData.append("reply_comment", replyComment || "");

  const response = await patchUrl(
    apiUrls.petition(petitionId),
    ReplyData,
    true,
    true
  );
  if (response?.status === 200) {
    return { success: true };
  } else {
    return { success: false };
  }
};

export const updatePetitionStage = async (stage, petitionId) => {
  const formData = new FormData();
  formData.append("state", stage);
  const response = await patchUrl(
    apiUrls.petition(petitionId),
    formData,
    true,
    true
  );
  return response;
};
