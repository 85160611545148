import React from "react";
import SideNaveBar from "./SideNaveBar";
import "./index.css";
import Spinner from "../components/commonComponents/Spinner";

function Layout({ children }) {
  return (
    <div className="complete-nav">
      <SideNaveBar />
      <Spinner />
      <main className="content">{children}</main>
      {/* Add footer if we have */}
    </div>
  );
}

export default Layout;
