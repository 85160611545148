import { postUrl } from "../../common/epetitionApi";
import { apiUrls } from "../../common/apiUrls";

export const userLogin = async (payload) => {
  try {
    const result = await postUrl(
      apiUrls.userLogin,
      {
        username: payload.username,
        password: payload.password,
        ip_address: "197.0.0.1",
      },
      false
    );

    if (result.status === 200) {
      if (
        result.data?.access_token === null ||
        result.data?.access_token === undefined ||
        !result.data?.user_id
      ) {
        return { success: false, tokenNotFound: true };
      }
      localStorage.clear();

      localStorage.setItem("access_token", result.data?.access_token);
      localStorage.setItem("user_id", result.data.user_id);

      return {
        success: true,
        message: "Login successful",
      };
    } else if (result.status === 401) {
      return {
        success: false,
        message: "Invalid credentials",
        status: 401,
        // Invalid credentials
      };
    } else if (result.status === 404) {
      return {
        success: false,
        notFound: true, // Handle 404 error
      };
    }
    return { success: false, message: "An error occurred during login" };
  } catch (err) {
    return { success: false, message: "An error occurred during login" };
  }
};
