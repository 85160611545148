import React from "react";
import PropTypes from "prop-types";
import Dropdown from "../Dropdown";
import TextInput from "../Input/index";
import { INPUT_TYPES } from "./FormConstants";
import TextArea from "../Input/TextArea";

function FormComponent({
  formName = "",
  formValue = null,
  onChange = null,
  model = [],
  styleClass = "",
  showSubmit = false,
  onSubmit = () => {},
  loading = false,
}) {
  const onChangeForm =
    ({ formName, validators = [], field }) =>
    (value, fieldName, validatedValue) => {
      onChange(formName, value, fieldName, validatedValue);
    };

  const renderTextInput = (field) => {
    return (
      <TextInput
        key={field.fieldName}
        value={
          formValue && formValue[field.fieldName]
            ? formValue[field.fieldName]
            : ""
        }
        placeholder={field.placeholder}
        disabled={field.disabled}
        required={field.required}
        styleClass={field.styleClass}
        type={field.type}
        label={field.label}
        fieldName={field.fieldName}
        onChange={onChangeForm({ formName, validators: field.validators })}
        error={formValue && formValue[`${field.fieldName}Error`]}
        id={field.fieldName}
        inputStyleClass={field.inputStyleClass}
        maxLength={field.maxLength}
        icon={field.icon}
        onClickIcon={field.onClickIcon}
        containerClass={field.containerClass}
      />
    );
  };

  const renderDropdown = (field) => {
    return (
      <Dropdown
        key={field.fieldName}
        value={
          formValue && formValue[field.fieldName]
            ? formValue[field.fieldName]
            : ""
        }
        placeholder={field.placeholder}
        disabled={field.disabled}
        required={field.required}
        styleClass={field.styleClass}
        type={field.type}
        label={field.label}
        fieldName={field.fieldName}
        onChange={onChangeForm({ formName, validators: field.validators })}
        error={formValue && formValue[`${field.fieldName}Error`]}
        options={field.options}
        inputStyleClass={field.inputStyleClass}
        // key={field.fieldName}
      />
    );
  };

  const renderTextArea = (field) => {
    return (
      <TextArea
        key={field.fieldName}
        value={
          formValue && formValue[field.fieldName]
            ? formValue[field.fieldName]
            : ""
        }
        placeholder={field.placeholder}
        disabled={field.disabled}
        required={field.required}
        styleClass={field.styleClass}
        type={field.type}
        label={field.label}
        fieldName={field.fieldName}
        onChange={onChangeForm({ formName, validators: field.validators })}
        error={formValue && formValue[`${field.fieldName}Error`]}
        id={field.fieldName}
        inputStyleClass={field.inputStyleClass}
        maxLength={field.maxLength}
        options={field.options}
        showFlag={field.showFlag}
        icon={field.icon}
        onClickIcon={field.onClickIcon}
        containerClass={field.containerClass}
      />
    );
  };

  const renderForm = (model = []) => {
    const form = [];
    model.forEach((field) => {
      switch (field.type) {
        case INPUT_TYPES.TEXT_INPUT:
        case INPUT_TYPES.PASSWORD:
          form.push(renderTextInput(field));
          break;
        case INPUT_TYPES.TEXT_AREA:
          form.push(renderTextArea(field));
          break;
        case INPUT_TYPES.DROP_DOWN:
          form.push(renderDropdown(field));
          break;
        default:
          form.push(renderTextInput(field));
      }
    });
    return form;
  };

  return (
    <div className="container mx-auto">
      <div className={`row  ${styleClass}`}>{renderForm(model)}</div>
    </div>
  );
}

FormComponent.propTypes = {
  formName: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
  formValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  model: PropTypes.array,
  showSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default FormComponent;
