import React from "react";
import Header from "../../../main/Header";
import { usePetitions } from "./Petitions.hook";
import "./index.css";
import PetitionStageTag from "./PetitionStageTag";
import { useEffect } from "react";
import {
  AddUserIcon,
  DeleteIcon,
  EditIcon,
  DownloadIcon,
} from "../../../components/SvgIcons/Icons";
import Button from "../../../components/commonComponents/Button";
import Empty from "../../../components/commonComponents/Empty";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import ForwardPetitionModal from "./ForwardModal/ForwardPetitionModal";
import PetitionDetailsModal from "./petitionDetailModal";
import { useState } from "react";
import { FaReply, FaDownload } from "react-icons/fa";
import ReplyModal from "./ReplyModal/ReplyModal";
import StageModal from "./StageModal/StageModal";
import { FiEdit2 } from "react-icons/fi";
import { fetchUser, selectUser } from "../../../store/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function PetitionsListing() {
  const {
    petitions,
    petitionTableRef,
    handleOnClickNewNavigation,
    error,
    loading,
    isModalOpen,
    handleCancel,
    handleConfirmDelete,
    handleDeleteClick,
    selectedPetitionToForward,
    setIsForwardPopupOpen,
    setSelectedPetitionToForward,
    isForwardPopupOpen,
    handleForwardPopupclose,
    handleForwardPetitionClick,
    handleStageClick,
    handleStagePopupClose,
    isStageEditPopupOpen,
    selectedPetitionToEditStage,
    isDownloading,
    handleDownload,
  } = usePetitions();

  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedPetitionData, setSelectedPetitionData] = useState(null);
  const [isReplyModalOpen, setIsReplyModalOpen] = useState(false);
  const [replyData, setReplyData] = useState(null);
  const [isStageModalOpen, setIsStageModalOpen] = useState(false); // State for StageModal
  const [selectedStagePetition, setSelectedStagePetition] = useState(null);

  const dispatch = useDispatch();
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    dispatch(fetchUser(userId));
  }, [dispatch]);
  const user = useSelector((state) => state.user);
  const role = user?.user?.role?.role_name;

  const handlePetitionClick = (data) => {
    setSelectedPetitionData(data);
    setIsDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setIsDetailsModalOpen(false);
    setSelectedPetitionData(null);
  };

  const handleReplyClick = (data) => {
    setReplyData(data);
    setIsReplyModalOpen(true);
  };

  const closeReplyModal = () => {
    setIsReplyModalOpen(false);
    setReplyData(null);
  };

  const handleStageModalClick = (data) => {
    setSelectedStagePetition(data);
    setIsStageModalOpen(true);
  };

  const closeStageModal = () => {
    setIsStageModalOpen(false);
    setSelectedStagePetition(null);
  };
  const getName = (firstName, lastName) => {
    let value = firstName ? "" + firstName : "";
    value = lastName ? value + " " + lastName : "";
    return value ? value : "-";
  };
  const showIcon = petitions.replyComment && petitions.replyComment.length > 15;

  return (
    <>
      {" "}
      <Header>
        {role === "admin" && (
          <Button
            title="Create Petition"
            onClick={handleOnClickNewNavigation}
          />
        )}
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="app-btn primary mr-3"
          table="table-xls"
          filename="petitions"
          sheet="petitions"
          buttonText="Download as XLS"
        />
      </Header>
      {loading !== "idle" && (
        <table
          className="table table-hover app-table mt-4 table-responsive"
          ref={petitionTableRef}
          id="table-xls"
        >
          <thead>
            <tr>
              <th>
                <p>Petition Number</p>
              </th>
              <th>
                <p>Created on </p>
              </th>
              <th>
                <p>Petitioner Name</p>
                {/* <p>Ph No</p> */}
              </th>
              <th>
                <p>Suspect name</p>
                {/* <p>Ph No</p> */}
              </th>
              <th>
                <p>Petition Source</p>
                <p>Download Petition</p>
              </th>

              <th>Reply</th>
              <th>Stage</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {petitions.map((data, index) => {
              return (
                <tr key={data.id}>
                  <td>
                    <PetitionStageTag
                      showIndicator={false}
                      stage={data.petitionId}
                      onClick={() => handlePetitionClick(data)}
                      style={{ cursor: "pointer" }}
                    />
                  </td>
                  <td>
                    <p>{moment(data.createdAt).format("DD-MM-YYYY")}</p>
                  </td>
                  <td>
                    <p>
                      {getName(
                        data.petitionerFirstname,
                        data.petitionerLastname
                      )}
                    </p>
                    {/* <p>{data.petitionerMobile || "-"}</p> */}
                  </td>
                  <td>
                    <p>
                      {getName(data.suspectFirstname, data.suspectLastname)}
                    </p>
                    {/* <p>{data.suspectMobile}</p> */}
                  </td>
                  <td>
                    <PetitionStageTag
                      showIndicator={false}
                      stage={data?.petitionSource?.name || ""}
                    />
                    <span
                      className="ms-4 mt-3 d-flex"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDownload(data)}
                      disabled={isDownloading}
                    >
                      <FaDownload />
                    </span>
                  </td>
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {data.replyComment ? (
                        <>
                          <span
                            style={{
                              flex: "10px",
                              marginRight: showIcon ? "9px" : "0",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              cursor: "pointer",
                            }}
                            onClick={() => handleReplyClick(data)}
                          >
                            {data.replyComment.length > 15
                              ? `${data.replyComment.slice(0, 15)}...`
                              : data.replyComment}
                          </span>
                        </>
                      ) : (
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "16px",
                            color: "black",
                          }}
                          onClick={() => handleReplyClick(data)}
                        >
                          <FaReply />
                        </span>
                      )}
                    </div>
                  </td>
                  <td>
                    <div onClick={() => handleStageClick(data)}>
                      <PetitionStageTag
                        stage={data.state}
                        onClick={() => handleStageModalClick(data)} // Open StageModal
                        style={{ cursor: "pointer" }}
                      />
                      {/* <FiEdit2 /> */}
                    </div>
                  </td>
                  {role === "admin" && (
                    <td>
                      <div className="d-flex align-items-center">
                        <span
                          className="cursor-pointer"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleForwardPetitionClick(data)}
                        >
                          <AddUserIcon />
                        </span>
                        <Link className="ms-3" to={`/edit-petition/${data.id}`}>
                          <EditIcon />
                        </Link>
                        <span
                          className="ms-3"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteClick(data)}
                        >
                          <DeleteIcon />
                        </span>
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <PetitionDetailsModal
        visible={isDetailsModalOpen}
        onCancel={closeDetailsModal}
        petitionData={selectedPetitionData}
      />
      <Modal
        title="Delete Petition"
        open={isModalOpen}
        onOk={handleConfirmDelete}
        onCancel={handleCancel}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this petition?</p>
      </Modal>
      {/* -------------------Repetitive code, Todo: Fox this optimize------------------ */}
      <ForwardPetitionModal
        open={isForwardPopupOpen}
        onClose={handleForwardPopupclose}
        petition={selectedPetitionToForward}
      />
      <ReplyModal
        open={isReplyModalOpen} // Why did we not used hook file to perform actions?
        onClose={closeReplyModal}
        petitionData={replyData}
      />
      <StageModal
        open={isStageEditPopupOpen}
        onClose={handleStagePopupClose}
        petition={selectedPetitionToEditStage}
      />
    </>
  );
}

export default PetitionsListing;
