import { INPUT_TYPES } from "../../components/commonComponents/FormComponent/FormConstants";
import { LuEyeOff } from "react-icons/lu";
export const loginModalConstant = [
  {
    type: INPUT_TYPES.TEXT_INPUT,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "username",
    inputStyleClass: "login-input-style-class",
    styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
    placeholder: "Enter your User Name",
    label: "Username",
    containerClass: "col-4",
    // validators: [validateEmail],
    // maxLength: 10,
    // showFlag: true,
  },
  {
    type: INPUT_TYPES.PASSWORD,
    icon: null,
    disabled: false,
    value: "",
    required: true,
    fieldName: "password",
    inputStyleClass: "login-input-style-class",
    placeholder: "Password",
    label: "Password",
    containerClass: "col-4",

    // validators: [validateEmail],
    // maxLength: 10,
    // showFlag: true,
  },
  //   {
  //     type: INPUT_TYPES.PASSWORD,
  //     icon: null,
  //     disabled: false,
  //     value: "",
  //     required: true,
  //     fieldName: "captcha",
  //     inputStyleClass: "login-input-style-class",
  //     styleClass: "login-styles-class col-md-9 col-sm-12 text-left",
  //     placeholder: "Captcha",
  //     label: "Enter Captcha",
  //   },
];
