import React, { useEffect } from "react";
import FormComponent from "../../components/commonComponents/FormComponent/FormComponent";
import { loginModalConstant } from "./Login.constants.js";
import "./index.css";
import { useLogin } from "./Login.hook.js";
import { INPUT_TYPES } from "../../components/commonComponents/FormComponent/FormConstants.js";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
} from "react-simple-captcha";
import TextInput from "../../components/commonComponents/Input/index.js";
function Login() {
  const {
    handleForm,
    handleSubmit,
    loading,
    loginForm,
    captcha,
    handleCaptchaChange,
    contextHolder,
  } = useLogin();

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);
  return (
    <>
      {contextHolder}
      <div className="login-container">
        <header className="header">E-Petition</header>
        <p className="login-header">Log In to your Account</p>
        {/* <p className="supporting-text">Welcome back! Please Enter your Details</p> */}
        <FormComponent
          styleClass="d-flex flex-column align-items-center"
          formName="loginForm"
          model={loginModalConstant}
          onChange={handleForm}
          formValue={loginForm.loginForm}
        />
        <div
          className=" d-flex align-items-center justify-content-center "
          style={{ paddingInline: "1.5rem" }}
        >
          <LoadCanvasTemplateNoReload />
        </div>
        <div className="container">
          <div className="row">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ paddingInline: "1.5rem" }}
            >
              <TextInput
                type={INPUT_TYPES.TEXT_INPUT}
                onChange={handleCaptchaChange}
                label="Captcha"
                fieldName="captcha"
                required={true}
                containerClass="col-4"
                value={captcha}
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ paddingInline: "1.5rem" }}
            >
              <button
                className="login-button col-4 mt-4"
                type={"submit"}
                loading={loading}
                onClick={handleSubmit}
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
