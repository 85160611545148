import React, { useEffect } from "react";
import { useState } from "react";
import { validateForm } from "../../../utils/validators";
import {
  complaintDetailsForm,
  petitionDetailsBriefForm,
  // petitionDetailsForm,
  suspectDetailsForm,
} from "../PetitionConstants";
import { notification } from "antd";
import { createOrEditPetition, getPetition } from "./CreatePetition.actions";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/commonActions/loaderSlice";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { useParams } from "react-router-dom";
import {
  getDivisions,
  getPoliceLimits,
  getPetitionSource,
} from "../../../store/masters/masterSlice";

export const useCreatePetition = () => {
  const [petition, setPetition] = useState({});
  const [api, contextHolder] = notification.useNotification();
  const [petitionAssets, setPetitionAssets] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editingParams = useParams();
  const [messageApi, msgContext] = message.useMessage();
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState({
    title: "",
    description: "",
    success: false,
  });
  const { divisions, gender, daysRequired, petitionSource, policeStations } =
    useSelector((state) => state.masterData);

  useEffect(() => {
    dispatch(getDivisions());
  }, []);

  useEffect(() => {
    dispatch(getPetitionSource());
  }, []);

  useEffect(() => {
    if (petition?.petitionDetailsForm?.division) {
      setLoader(true);
      dispatch(getPoliceLimits(petition?.petitionDetailsForm?.division));
      setLoader(false);
    }
  }, [petition?.petitionDetailsForm?.division]);

  const getDetails = async () => {
    if (editingParams.petitionId) {
      dispatch(setLoader(true));
      let results = await getPetition(editingParams.petitionId);
      if (results.success) {
        setPetition({
          ...results.form,
        });
      } else {
        setMessageModalContent((prev) => ({
          title: "Something went wrong!",
          description:
            results?.status === 404
              ? "The petition your are trying to edit is not found"
              : "Unable get petition details!",
          success: false,
        }));
        setIsMessageModalOpen(true);
      }
      dispatch(setLoader(false));
    }
  };

  useEffect(() => {
    getDetails();
  }, [editingParams]);

  const handleChange = (formName, value, fieldName) => {
    setPetition((prevForm) => ({
      ...prevForm,
      [formName]: {
        ...prevForm[formName],
        [fieldName]: value,
        [`${fieldName}Error`]: "",
      },
    }));
    if (fieldName === "division") {
      setLoader(true);
      dispatch(getPoliceLimits(value));
      setLoader(false);
    }
  };

  const handleSubmit = async () => {
    const complaintValidations = validateForm(
      complaintDetailsForm,
      petition.complaintDetailsForm
    );
    const suspectValidations = validateForm(
      suspectDetailsForm,
      petition.suspectDetailsForm
    );
    const briefValidations = validateForm(
      petitionDetailsBriefForm,
      petition.petitionDetailsBriefForm
    );
    // const petitionValidation = validateForm(
    //   petitionDetailsForm,
    //   petition.petitionDetailsForm
    // );
    setPetition((prevForm) => {
      return {
        ...prevForm,
        complaintDetailsForm: {
          ...complaintValidations.newForm,
        },
        suspectDetailsForm: {
          ...suspectValidations.newForm,
        },
        petitionDetailsBriefForm: {
          ...briefValidations.newForm,
        },
        petitionDetailsForm: {
          // ...petitionValidation.newForm,
        },
      };
    });
    if (
      !complaintValidations.isValid ||
      !suspectValidations.isValid ||
      !briefValidations.isValid
      // !petitionValidation.isValid
    ) {
      return api.error({
        message: "Please fill required fields.",
        description: "You need fill all fields which are marked with *",
        duration: 4,
      });
    }
    dispatch(setLoader(true));
    const response = await createOrEditPetition(
      petition,
      editingParams?.petitionId,
      petitionAssets
    );
    if (response.success) {
      setMessageModalContent({
        title: "Success!",
        description: editingParams?.petitionId
          ? "Succesfully updated petition!"
          : "A petition was created sucessfully.",
        success: true,
      });
      setIsMessageModalOpen(true);
      setTimeout(() => {
        setIsMessageModalOpen(false);
        navigate("/petitions");
      }, [2000]);
    } else {
      setMessageModalContent({
        title: "Try again!",
        description: "Your petition hasn’t been submitted.",
        success: false,
      });
      setIsMessageModalOpen(true);
    }
    dispatch(setLoader(false));
  };

  const handleAssetChange = (files) => {
    setPetitionAssets([...files]);
  };

  const handleCancel = () => {
    navigate("/petitions");
  };

  const handleMessageClose = () => {
    setMessageModalContent({
      title: "",
      description: "",
      success: false,
    });
    setIsMessageModalOpen(false);
  };

  return {
    petition,
    handleChange,
    handleSubmit,
    contextHolder,
    handleAssetChange,
    handleCancel,
    msgContext,
    editingParams,
    isMessageModalOpen,
    setIsMessageModalOpen,
    messageModalContent,
    handleMessageClose,
    divisions,
    daysRequired,
    gender,
    policeStations,
    petitionSource,
  };
};
