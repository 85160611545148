import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUrl } from "../../common/epetitionApi";
import { apiUrls } from "../../common/apiUrls";

const INITIAL_STATE = {
  user: null,
  // Add roles to the initial state
  loading: false,
  isLoggedIn: false,
  error: null,
};

export const fetchUser = createAsyncThunk(
  "user/getUserById",
  async (userId, _) => {
    const response = await getUrl(apiUrls.userDetails(userId));
    if (response.success === false) {
      throw new Error("Error while fetching data");
    }
    return response.data;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {
    addUser: (state, action) => {
      state.user = action.payload;
    },
    setLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    clearUser: (state) => {
      state.user = null; // Clear roles when user is cleared
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
      })
      .addCase(fetchUser.rejected, (state) => {
        state.user = null;
        state.error = "Error occurred while getting user details!";
        state.loading = false;
      })
      .addCase(fetchUser.pending, (state) => {
        state.loading = true;
      });
  },
});

// Selectors to get user and roles data from the state
export const selectUser = (state) => state.user;

// Exporting the reducer and actions
export const { addUser, setLoggedIn, clearUser } = userSlice.actions;
export default userSlice.reducer;
