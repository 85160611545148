import React from "react";
import "./index.css";
import { useSelector } from "react-redux";

function Spinner() {
  const loader = useSelector((state) => state.loader);

  return (
    loader?.loading && (
      <div className="loading-overlay">
        <div className="spinner"></div>
      </div>
    )
  );
}

export default Spinner;
