// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container, .success-container {
  width: 48px;
  height: 48px;
  padding: 12px;
  border-radius: var(--radius-full, 9999px);
  background: var(--Colors-Background-bg-error-secondary, #fee4e2);
}

.success-container {
  background: var(--Colors-Background-bg-success-secondary, #DCFAE6);
}`, "",{"version":3,"sources":["webpack://./src/components/SvgIcons/index.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,yCAAyC;EACzC,gEAAgE;AAClE;;AAEA;EACE,kEAAkE;AACpE","sourcesContent":[".error-container, .success-container {\n  width: 48px;\n  height: 48px;\n  padding: 12px;\n  border-radius: var(--radius-full, 9999px);\n  background: var(--Colors-Background-bg-error-secondary, #fee4e2);\n}\n\n.success-container {\n  background: var(--Colors-Background-bg-success-secondary, #DCFAE6);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
