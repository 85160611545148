import { INPUT_TYPES } from "../../components/commonComponents/FormComponent/FormConstants";
import { genderMaster } from "../../store/masters/masterConstants";

export const complaintDetailsForm = [
  {
    fieldName: "firstName",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "First Name",
    required: true,
  },
  {
    fieldName: "lastName",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Last Name",
    required: true,
  },
  {
    fieldName: "age",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Age",
    required: true,
  },
  {
    type: "dropdown",
    required: true,
    label: "Gender",
    fieldName: "gender",
    options: genderMaster,
  },
  {
    fieldName: "phoneNumber",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Phone Number",
    required: true,
  },
  {
    fieldName: "community",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Community",
    required: true,
  },
  {
    fieldName: "address",
    type: INPUT_TYPES.TEXT_AREA,
    placeholder: "",
    label: "Address",
    required: true,
    placeholder: "Enter Area, village, landmark...",
    containerClass: "col-12",
  },
  // {
  //   fieldName: "area",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Area",
  //   required: false,
  //   // containerClass: "col-3",
  // },
  // {
  //   fieldName: "village",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Village/Town",
  //   required: false,
  //   // containerClass: "col-3",
  // },
  // {
  //   fieldName: "landmark",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Landmark",
  //   required: false,
  //   // containerClass: "col-3",
  // },
];

export const suspectDetailsForm = [
  {
    fieldName: "firstName",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "First Name",
    required: false,
    // containerClass: "col-3",
  },
  {
    fieldName: "lastName",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Last Name",
    required: false,
    // containerClass: "col-3",
  },
  {
    fieldName: "age",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Age",
    required: false,
    // containerClass: "col-3",
  },
  {
    type: "dropdown",
    required: false,
    label: "Gender",
    fieldName: "gender",
    options: genderMaster,
  },
  {
    fieldName: "phoneNumber",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Phone Number",
    required: false,
    // containerClass: "col-3",
  },
  {
    fieldName: "community",
    type: INPUT_TYPES.TEXT_INPUT,
    placeholder: "",
    label: "Community",
    required: false,
    // containerClass: "col-3",
  },
  {
    fieldName: "address",
    type: INPUT_TYPES.TEXT_AREA,
    placeholder: "",
    label: "Address",
    required: false,
    placeholder: "Enter Area, village, landmark...",
    containerClass: "col-12",
  },
  // {
  //   fieldName: "doorNo",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Door no",
  //   required: true,
  //   // containerClass: "col-3",
  // },
  // {
  //   fieldName: "area",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Area",
  //   required: false,
  //   // containerClass: "col-3",
  // },
  // {
  //   fieldName: "village",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Village/Town",
  //   required: false,
  //   // containerClass: "col-3",
  // },
  // {
  //   fieldName: "landmark",
  //   type: INPUT_TYPES.TEXT_INPUT,
  //   placeholder: "",
  //   label: "Landmark",
  //   required: false,
  //   // containerClass: "col-3",
  // },
];

export const petitionDetailsBriefForm = [
  {
    fieldName: "briefOfIncident",
    type: INPUT_TYPES.TEXT_AREA,
    placeholder: "Detailed Report of the incident",
    label: "Brief of Incident",
    required: true,
    containerClass: "col-12",
  },
];

export const getPetitionDetailsForm = (
  divisions,
  policeStations,
  petitionSource,
  daysRequired
) => {
  let model = [
    {
      type: "dropdown",
      required: true,
      label: "Division",
      fieldName: "division",
      options: divisions,
    },
    {
      type: "dropdown",
      required: true,
      label: "PS Limit",
      fieldName: "psLimit",
      options: policeStations,
    },
    {
      type: "dropdown",
      required: true,
      label: "Petition Source",
      fieldName: "petitionSource",
      options: petitionSource,
    },
    {
      type: "dropdown",
      required: true,
      label: "Days Required",
      fieldName: "daysRequired",
      options: daysRequired,
    },
  ];
  return model;
};
