import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// This component with clear the information relate to access_token and redirect to /join route
function Logout() {
  const navigation = useNavigate();
  const access_token = localStorage.getItem("access_token");
  if (access_token !== null || access_token !== undefined) {
    localStorage.clear();
  }
  useEffect(() => {
    navigation("/login");
  }, [navigation]);
  return <></>;
}

export default Logout;
