export const getBaseUrl = () => {
  return process.env.REACT_APP_FEEDBACK_API_BASE_URL_PROD;
};

export const apiBaseUrl = {
  baseUrl: process.env.REACT_APP_QR_PRO_STUDIO_API_BASE_URL,
};

export const apiUrls = {
  userLogin: "/login/",
  userDetails: (id) => `/user/${id}/`,
  newPetition: "/petition/",
  petitions: "/petition/",
  petition: (id) => `/petition/${id}/`,
  divisions: `/division`,
  ps: (divisionId) => `/ps?division_id=${divisionId}`,
  petitionSource: "/petition-source/",
  forwardPetiton: (petitionId) => `/forward-petition/${petitionId}/`,
};
