import React from "react";
import Header from "../../../main/Header";
import FormComponent from "../../../components/commonComponents/FormComponent/FormComponent";
import {
  complaintDetailsForm,
  getPetitionDetailsForm,
  petitionDetailsBriefForm,
  suspectDetailsForm,
} from "../PetitionConstants";
import RightHeader from "./RightHeader";
import { Divider } from "antd";
import "./index.css";
import { useCreatePetition } from "./CreatePetition.hook";
import FileInput from "../../../components/commonComponents/FileInput";
import TopRightModal from "../../../components/commonComponents/Modal/ModalNotification";
import { ErrorIcon, SuccessIcon } from "../../../components/SvgIcons/Icons";

function CreatePetition() {
  const {
    handleChange,
    petition,
    handleSubmit,
    contextHolder,
    handleAssetChange,
    handleCancel,
    msgContext,
    editingParams,
    isMessageModalOpen,
    setIsMessageModalOpen,
    messageModalContent,
    handleMessageClose,
    divisions,
    policeStations,
    daysRequired,
    gender,
    petitionSource,
  } = useCreatePetition();
  return (
    <>
      {contextHolder}
      {msgContext}
      {/* <Modal isOpen={true} /> */}
      <TopRightModal
        open={isMessageModalOpen}
        onClose={handleMessageClose}
        title={messageModalContent.title}
      >
        <div className="d-flex flex-column align-items-center justify-content-center">
          {!messageModalContent.success ? <ErrorIcon /> : <SuccessIcon />}
          <p className="form-name mt-3">{messageModalContent.title}</p>
          <p className="message-description mt-2">
            {messageModalContent.description}
          </p>
        </div>
      </TopRightModal>
      <Header>
        <RightHeader onSubmit={handleSubmit} onCancel={handleCancel} />
      </Header>
      {editingParams?.petitionId && (
        <p className="mt-4 form-name">
          Editing Petition - {petition?.metadata?.petitionId}
        </p>
      )}
      <div className="row mt-4 d-flex align-items-center justify-content-between ">
        <div className="form-root">
          <p className="form-name mb-2 mt-2">Complainant Details</p>
          <Divider className="mt-2 mb-4" />
          <div className="form-conatiner">
            <FormComponent
              formName="complaintDetailsForm"
              model={complaintDetailsForm}
              onChange={handleChange}
              formValue={petition?.complaintDetailsForm}
            />
          </div>
        </div>
        <div className="form-root">
          <p className="form-name mb-2 mt-2">Suspect Details</p>
          <Divider className="mt-2 mb-4" />
          <div className="form-conatiner">
            <FormComponent
              formName="suspectDetailsForm"
              model={suspectDetailsForm}
              onChange={handleChange}
              formValue={petition?.suspectDetailsForm}
            />
          </div>
        </div>
        {/* </div> */}
      </div>
      <div>
        <p className="form-name mb-2 mt-5">Petition Details</p>
        <Divider className="mt-2 mb-4" />
        <div className="form-conatiner">
          <div className="row">
            <div className="col-6">
              <FormComponent
                onChange={handleChange}
                formName="petitionDetailsBriefForm"
                model={petitionDetailsBriefForm}
                formValue={petition.petitionDetailsBriefForm}
              />
            </div>
            <div className="col-6">
              <FormComponent
                onChange={handleChange}
                formName="petitionDetailsForm"
                model={getPetitionDetailsForm(
                  divisions,
                  policeStations,
                  petitionSource,
                  daysRequired
                )}
                formValue={petition.petitionDetailsForm}
              />
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-6">
              <div className="container">
                <FileInput
                  fieldName={"petitionAssets"}
                  label="Pdf/Image"
                  containerClass="col-12"
                  onChange={handleAssetChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreatePetition;
