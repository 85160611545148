import React, { useState, useEffect } from "react";
import { Input, Button, notification } from "antd";
import TopRightModal from "../../../../components/commonComponents/Modal/ModalNotification";
import { postUrl } from "../../../../common/epetitionApi";
import { replyComment } from "../PetitionListing.actions";
import { useDispatch } from "react-redux";
import { fetchPetitions } from "../../../../store/petitions/petitionsSlice";

const ReplyModal = ({ open = false, onClose = () => {}, petitionData }) => {
  const [replyText, setReplyText] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [api, notificationContext] = notification.useNotification();

  useEffect(() => {
    if (open) {
      setReplyText(petitionData.replyComment);
    }
  }, [petitionData]);

  const handleReplyChange = (e) => {
    setReplyText(e.target.value);
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleReply = async () => {
    setLoading(true);

    const result = await replyComment(replyText, petitionData?.id);
    setLoading(false);
    dispatch(fetchPetitions());

    if (result.success) {
      api.success({
        message: "Reply sent successfully",
      });
    } else {
      api.error({
        message: "Failed to send reply",
      });
    }

    handleOnClose();
  };

  return (
    <div>
      {notificationContext}
      <TopRightModal onClose={handleOnClose} open={open}>
        <div className="d-flex flex-column justify-content-center mt-3">
          <div className="d-flex align-items-center">
            <strong className="ms-3">
              Reply to Petition {petitionData?.petitionId || "-"}
            </strong>
          </div>
          <div className="mt-4">
            <Input.TextArea
              rows={4}
              placeholder="Type your reply here..."
              value={replyText}
              onChange={handleReplyChange}
            />
          </div>
        </div>
        <Button
          loading={loading}
          type="primary"
          className="w-100 mt-3"
          onClick={handleReply}
        >
          Reply
        </Button>
      </TopRightModal>
    </div>
  );
};

export default ReplyModal;
