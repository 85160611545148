import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../store/commonActions/loaderSlice";
import { fetchPetitions } from "../../../store/petitions/petitionsSlice";
import { useSelector } from "react-redux";
import { deleteUrl, getUrl, postUrl } from "../../../common/epetitionApi";
import { notification } from "antd";

export const usePetitions = () => {
  const dispatch = useDispatch();
  const { petitions, loading, error } = useSelector((state) => state.petitions);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPetition, setSelectedPetition] = useState(null);
  const [selectedPetitionToForward, setSelectedPetitionToForward] = useState(
    {}
  );
  const [isForwardPopupOpen, setIsForwardPopupOpen] = useState(false);
  const [isStageEditPopupOpen, setIsStageEditPopupOpen] = useState(false);
  const [selectedPetitionToEditStage, setSelectedPetitionEditStage] = useState(
    {}
  );
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    dispatch(fetchPetitions());
  }, []);

  const handleOnClickNewNavigation = () => {
    navigate("/new-petition");
  };

  const handleDeleteClick = (petition) => {
    setSelectedPetition(petition);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedPetition(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedPetition) {
      try {
        dispatch(setLoader(true));
        const response = await deleteUrl(`/petition/${selectedPetition.id}/`);
        if (response.status === 200) {
          dispatch(fetchPetitions());
          setIsModalOpen(false);
          notification.success({
            message: "Petition Deleted",
            description: "The petition has been successfully deleted.",
            placement: "topRight",
          });
        } else if (response.status === 404) {
          notification.error({
            message: "Delete Failed",
            description:
              "Failed to delete the petition. Please try again later.",
            placement: "topRight",
          });
        } else {
          notification.error({
            message: "Something went Wrong",
            description:
              "Error occurred while deleting. Please try again later.",
            placement: "topRight",
          });
        }
      } catch (error) {
        console.error("An error occurred while deleting the petition:", error);
      } finally {
        setSelectedPetition(null);
        dispatch(setLoader(false));
        setIsModalOpen(false);
      }
    }
  };
  const handleDownload = async (selectedPetition) => {
    if (!selectedPetition || isDownloading) return;

    setIsDownloading(true);

    try {
      const response = await getUrl(`/petition/${selectedPetition.id}/`);

      console.log(response);

      if (response.success) {
        const documents = response.data.documents;

        // Trigger download for each document
        documents.forEach(async (file) => {
          try {
            const presignedUrl = file.document;
            const response = await fetch(presignedUrl);
            const blob = await response.blob();
            const link = document.createElement("a");
            const url = window.URL.createObjectURL(blob);

            link.href = url;

            const urlSegments = presignedUrl.split('/');
            const fullFileName = urlSegments[urlSegments.length - 1];
            const fileName = decodeURIComponent(fullFileName.split('?')[0]);

            // Fallback to Content-Disposition header if filename is not in the URL
            if (!fileName) {
                const contentDisposition = response.headers.get('Content-Disposition');
                if (contentDisposition) {
                    const match = contentDisposition.match(/filename="?([^"]+)"?/);
                    if (match && match.length === 2) {
                        fileName = match[1];
                    }
                }
            }

            // link.download = file.document.split("/").pop() || `file-${file.id}`;
            // link.style.display = "none";
            // document.body.appendChild(link);
            // link.click();
            // document.body.removeChild(link);

            link.setAttribute('download', fileName || 'downloaded_file');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Revoke the object URL to release memory
            window.URL.revokeObjectURL(url);
          } catch (error) {
            console.error("Error downloading file:", error);
          }
        });

        // Optionally reset the state after all downloads are triggered
        setTimeout(
          () => setIsDownloading(false),
          documents.length * 500 + 3000
        ); // Re-enable button after all downloads are triggered
      } else {
        console.error("Failed to get documents");
        setIsDownloading(false);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
      setIsDownloading(false);
    }
  };

  const handleForwardPopupclose = () => {
    setSelectedPetitionToForward({});
    setIsForwardPopupOpen(false);
  };

  const handleForwardPetitionClick = (selectedPetition) => {
    setSelectedPetitionToForward(selectedPetition);
    setIsForwardPopupOpen(true);
  };

  const handleStageClick = (selectedPetition) => {
    setSelectedPetitionEditStage(selectedPetition);
    setIsStageEditPopupOpen(true);
  };

  const handleStagePopupClose = () => {
    setSelectedPetitionEditStage({});
    setIsStageEditPopupOpen(false);
  };

  return {
    petitions,
    handleOnClickNewNavigation,
    loading,
    error,
    isModalOpen,
    handleCancel,
    handleConfirmDelete,
    handleDeleteClick,
    isForwardPopupOpen,
    setIsForwardPopupOpen,
    selectedPetitionToForward,
    setSelectedPetitionToForward,
    handleForwardPopupclose,
    handleForwardPetitionClick,
    handleStageClick,
    handleStagePopupClose,
    isStageEditPopupOpen,
    selectedPetitionToEditStage,
    isDownloading,
    handleDownload,
  };
};
