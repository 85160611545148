import React from "react";

function Empty() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{height: "80vh"}}>
      <div className="card d-flex flex-column justify-content-center align-items-center w-50">
        <img
          src="/not-found.jpg"
          alt="no results"
          style={{ width: 400, height: 400 }}
        />
        <p className="pb-3">No petitions found!</p>
      </div>
    </div>
  );
}

export default Empty;
