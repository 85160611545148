import { useEffect, useState } from "react";
import "./index.css";
import { loginModalConstant } from "./Login.constants";
import { validateForm } from "../../utils/validators";
import { userLogin } from "./Auth.actions";
import { useNavigate } from "react-router-dom";
import { validateCaptcha } from "react-simple-captcha";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../store/slices/userSlice";
export const useLogin = () => {
  const [loginForm, setLoginForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [captcha, setCaptcha] = useState("");

  const [loginModal, setLoginModal] = useState(loginModalConstant);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleForm = (formName, value, fieldName) => {
    setLoginForm((prevForm) => {
      return {
        ...prevForm,
        [formName]: {
          ...prevForm[formName],
          [fieldName]: value,
        },
      };
    });
  };

  const handleCaptchaChange = (value, _) => {
    setCaptcha(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateCaptcha(captcha, true)) {
      api.error({
        message: "Invalid Captcha",
        duration: 4,
      });
      return;
    }
    const { newForm, isValid } = validateForm(loginModal, loginForm.loginForm);
    setLoginForm({ loginForm: { ...newForm } });

    if (!isValid) {
      api.error({
        message: "Validation Error",
        description: "Please fill out all required fields correctly.",
        duration: 4,
      });
      return;
    }

    const loginData = {
      username: loginForm.loginForm.username,
      password: loginForm.loginForm.password,
    };
    setLoading(true);

    const result = await userLogin(loginData);

    if (result.success) {
      notification.success({
        message: "Login Successful",
        description: "You have been successfully logged in.",
        duration: 4,
      });
      navigate("/petitions");
    } else if (result.status === 401) {
      api.error({
        message: "Invalid Credentials",
        description: "The username or password you entered is incorrect.",
        duration: 4,
      });
    } else {
      api.error({
        message: "Something Went Wrong",
        description: "An unexpected error occurred. Please try again later.",
        duration: 4,
      });
    }
  };

  return {
    loginForm,
    handleSubmit,
    loading,
    message,
    handleForm,
    loginModal,
    captcha,
    handleCaptchaChange,
    contextHolder,
  };
};
