import React from "react";
import "./index.css"

export default function NotFound() {
  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <img src="/not-found.jpg" alt="not found" className="notfound-img" />
      <p className="form-name">This page isn't available right now!</p>
    </div>
  );
}
