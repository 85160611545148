// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-name {
  color: var(--colors-text-text-primary-900, #101828);
  /* Text lg/Semibold */
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-lg, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Line-height-text-lg, 28px); /* 155.556% */
}

.form-root {
  width: 49%;
}

.form-conatiner {
  border-radius: var(--radius-xl, 12px);
  border: 1px solid var(--Colors-Border-border-disabled, #d0d5dd);
  background: var(--Colors-Background-bg-primary, #fff);
  box-shadow: 0px 1px 2px 0px
    var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));
  padding: 10px;
  /* padding-block: 15px; */
}

.message-description {
  color: var(--colors-text-text-tertiary-600, #475467);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-sm, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-sm, 20px); 
}
`, "",{"version":3,"sources":["webpack://./src/pages/Petitions/CreatePetition/index.css"],"names":[],"mappings":"AAAA;EACE,mDAAmD;EACnD,qBAAqB;EACrB,uDAAuD;EACvD,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;EAChB,6CAA6C,EAAE,aAAa;AAC9D;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qCAAqC;EACrC,+DAA+D;EAC/D,qDAAqD;EACrD;mEACiE;EACjE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,oDAAoD;EACpD,uDAAuD;EACvD,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;EAChB,6CAA6C;AAC/C","sourcesContent":[".form-name {\n  color: var(--colors-text-text-primary-900, #101828);\n  /* Text lg/Semibold */\n  font-family: var(--Font-family-font-family-body, Inter);\n  font-size: var(--Font-size-text-lg, 18px);\n  font-style: normal;\n  font-weight: 600;\n  line-height: var(--Line-height-text-lg, 28px); /* 155.556% */\n}\n\n.form-root {\n  width: 49%;\n}\n\n.form-conatiner {\n  border-radius: var(--radius-xl, 12px);\n  border: 1px solid var(--Colors-Border-border-disabled, #d0d5dd);\n  background: var(--Colors-Background-bg-primary, #fff);\n  box-shadow: 0px 1px 2px 0px\n    var(--Colors-Effects-Shadows-shadow-xs, rgba(16, 24, 40, 0.05));\n  padding: 10px;\n  /* padding-block: 15px; */\n}\n\n.message-description {\n  color: var(--colors-text-text-tertiary-600, #475467);\n  font-family: var(--Font-family-font-family-body, Inter);\n  font-size: var(--Font-size-text-sm, 14px);\n  font-style: normal;\n  font-weight: 400;\n  line-height: var(--Line-height-text-sm, 20px); \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
