import React from "react";
import Button from "../../../components/commonComponents/Button";
import TextInput from "../../../components/commonComponents/Input";

function RightHeader({onSubmit, onCancel}) {
  return (
    <div className="d-flex align-items-center">
      <Button title="Cancel" className="danger me-4" onClick={onCancel} />
      <Button title="Save" className="success" onClick={onSubmit} />
    </div>
  );
}

export default RightHeader;
