import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import "./index.css";
import classNames from "classnames";
import { ChevronDown } from "../../SvgIcons/Icons";

function Dropdown({
  type = "text",
  onChange = () => {},
  fieldName = "",
  placeholder = "Please select",
  required = false,
  label = "",
  className = "",
  value = "",
  options = [],
  containerClass = "col-6",
  error = "",
}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleInputChange = (e) => {
    if (onChange) {
      onChange(e.target.value, fieldName);
    }
  };

  const handleOptionClick = (optionValue) => {
    onChange(optionValue, fieldName);
    setOpen(false);
  };

  const handleFocus = () => {
    setOpen(true);
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (inputRef.current && dropdownRef.current) {
      dropdownRef.current.style.width = `${inputRef.current.offsetWidth}px`;
    }
  }, [open]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getNameBasedOnValue = (value) => {
    if (value) {
      const option = options.find((option) => option.value === value);
      return option ? option.name : "";
    }
    return "";
  };

  useEffect(() => {
    setName(getNameBasedOnValue(value, options));
  }, [value]);

  return (
    <div className={`app-input-container ${containerClass}`}>
      {label && (
        <label
          htmlFor={fieldName}
          className={classNames("input-label", {
            "input-required": required,
          })}
        >
          {label}
        </label>
      )}
      <div className="drop-down">
        <input
          ref={inputRef}
          type={type}
          id={fieldName}
          onChange={handleInputChange}
          placeholder={placeholder}
          className={`app-input input-dropdown ${className} ${
            error ? "error-input" : ""
          }`}
          value={getNameBasedOnValue(value)}
          readOnly={true}
          onFocus={handleFocus}
        />
        <span
          className={classNames("chevron-icon", {
            "chevron-icon": !open,
            "chevron-icon-rotate": open,
          })}
        >
          <ChevronDown />
        </span>
      </div>
      {open && (
        <div className="dropdown-options-container" ref={dropdownRef}>
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => handleOptionClick(option.value)}
              className={`${value === option.value ? "selected" : ""}`}
            >
              {option.name}
            </button>
          ))}
          {options.length === 0 ? <span className="fs-12">No data available</span> : null}
        </div>
      )}
    </div>
  );
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default Dropdown;
