import React from "react";
import "./index.css"; // Make sure this path is correct
import classNames from "classnames";

const TextArea = ({
  label,
  placeholder,
  onChange,
  fieldName,
  required = false,
  value = "",
  containerClass = "col-6",
  className = "",
  maxLength,
  type,
  error="",
}) => {

  const handleOnChange = (e) => {
    onChange(e.target.value, fieldName);
  }

  return (
    <div className={`app-input-container ${containerClass}`}>
      {label ? (
        <label
          htmlFor={fieldName}
          className={classNames("input-label", {
            "input-required": required,
          })}
        >
          {label}
        </label>
      ) : null}
      <div>
        <textarea
          type={type}
          id={fieldName}
          onChange={handleOnChange}
          placeholder={placeholder}
          maxLength={maxLength}
          className={`app-input app-input-textarea  ${className} ${error ? "error-input": ""}`}
          value={value}
        />
      </div>
    </div>
  );
};

export default TextArea;
