import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "../pages/Login";
import CreatePetition from "../pages/Petitions/CreatePetition";
import Protected from "../protected";
import PublicRoute from "../protected/PublicRoute";
import Logout from "../components/Logout/Logout";
import PetitionsListing from "../pages/Petitions/PetitionsListing";
import Test from "../Test";
import NotFound from "../pages/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/login",
    element: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    path: "/petitions",
    element: (
      <Protected>
        <PetitionsListing />
      </Protected>
    ),
  },
  {
    path: "/petitions",
    element: (
      <Protected>
        <PetitionsListing />
      </Protected>
    ),
  },
  {
    path: "/new-petition",
    element: (
      <Protected>
        <CreatePetition />
      </Protected>
    ),
  },
  {
    path: "/edit-petition/:petitionId",
    element: (
      <Protected>
        <CreatePetition />
      </Protected>
    ),
  },
  {
    path: "/logout",
    element: (
      <Protected>
        <Logout />
      </Protected>
    ),
  },
  {
    path: "/test",
    element: <Test />
  },
  {
    path: "*",
    element: <NotFound />
  }
]);

function Routes() {
  return <RouterProvider router={router} />;
}

export default Routes;
