import { buildCreateSlice, createSlice } from "@reduxjs/toolkit";
import { fetchPetitions } from "../petitions/petitionsSlice";
import { getPoliceLimits } from "../masters/masterSlice";

const initialState = {
  loading: false,
};

const loaderSlice = createSlice({
  name: "loader",
  initialState: initialState,
  reducers: {
    setLoader: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPetitions.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchPetitions.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchPetitions.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPoliceLimits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPoliceLimits.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getPoliceLimits.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setLoader } = loaderSlice.actions;
export default loaderSlice.reducer;
