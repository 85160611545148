import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUrl } from "../../common/epetitionApi";
import { apiUrls } from "../../common/apiUrls";
import { convertToCamelCase } from "../../utils/camelCaseConverter";

export const fetchPetitions = createAsyncThunk(
  "petitions/list",
  async (payload, _) => {
    const result = await getUrl(apiUrls.petitions);
    if (result.success) {
      return convertToCamelCase(result.data);
    } else {
      throw new Error("Something went wrong while fetching petiontions");
    }
  }
);

let petitionsSlice = createSlice({
  name: "petitions",
  initialState: {
    petitions: [],
    loading: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPetitions.fulfilled, (state, action) => {
        let values = action.payload;
        state.petitions = values;
        state.loading = "fulfilled";
        state.error = "";
      })
      .addCase(fetchPetitions.pending, (state) => {
        state.petitions = [];
        state.error = "";
        state.loading = "idle";
      })
      .addCase(fetchPetitions.rejected, (state, action) => {
        state.error = "Unable to fetch petitions at the moment!";
        state.loading = "error";
      });
  },
});

export default petitionsSlice.reducer;
