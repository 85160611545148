// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-container {
  border: 2px dashed #d0d5dd;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 8px;
  padding-block: 10px;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.text-standard {
  color: var(--colors-text-text-primary-900, #101828);
  font-family: var(--Font-family-font-family-body, Inter);
  font-size: var(--Font-size-text-md, 14px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Line-height-text-md, 24px); /* 150% */
}
`, "",{"version":3,"sources":["webpack://./src/components/commonComponents/FileInput/index.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,kDAAkD;EAClD,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,mDAAmD;EACnD,uDAAuD;EACvD,yCAAyC;EACzC,kBAAkB;EAClB,gBAAgB;EAChB,6CAA6C,EAAE,SAAS;AAC1D","sourcesContent":[".file-upload-container {\n  border: 2px dashed #d0d5dd;\n  background: #fff;\n  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);\n  border-radius: 8px;\n  padding: 8px;\n  padding-block: 10px;\n  font-size: 14px;\n  font-weight: 400;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.text-standard {\n  color: var(--colors-text-text-primary-900, #101828);\n  font-family: var(--Font-family-font-family-body, Inter);\n  font-size: var(--Font-size-text-md, 14px);\n  font-style: normal;\n  font-weight: 400;\n  line-height: var(--Line-height-text-md, 24px); /* 150% */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
